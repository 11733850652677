import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ToastItem.module.scss';

function ToastItem(props) {
  const {
    removeToast, id, content, delay,
  } = props;

  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(show);
      if (show) {
        setTimeout(() => {
          setShow(false);
        }, delay);
      } else {
        removeToast(id);
      }
    }, show ? 100 : 300);
  }, [show]);

  if (!open && !show) {
    return null;
  }

  return (
    <div className={`${styles.Container} ${open && show ? styles['Container--show'] : styles['Container--hide']}`}>
      {typeof content === 'string' ? <div className={styles.DefaultContent}>{content}</div> : content}
    </div>
  );
}

ToastItem.defaultProps = {
  delay: 3000,
};

ToastItem.propTypes = {
  removeToast: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  content: PropTypes.node.isRequired,
  delay: PropTypes.number,
};

export default ToastItem;
