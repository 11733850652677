import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { produce } from 'immer';
import { createPortal } from 'react-dom';
import ToastItem from './ToastItem';

const toastRoot = document.getElementById('toast-container');

export const ToastContext = React.createContext(null);

function Toast(props) {
  const { children } = props;
  const [toastList, setToastList] = useState([]);

  /**
   * show a toast
   * ```tsx
   * {
   *  content: string | node;
   *  delay: number; // 3000 is default
   * }
   * ```
   */
  const addToast = useCallback(function addToastItem(data) {
    setToastList(produce((draft) => {
      const id = Math.random();
      draft.push({
        ...data,
        id,
      });
    }));
  }, [toastList]);

  window.addToast = addToast;

  /**
   * removes a toast by its id from toastList
   */
  const removeToast = useCallback(function removeToastItem(id) {
    const toastItemIndex = toastList.findIndex((toastItem) => toastItem.id === id);
    setToastList(produce((draft) => {
      draft.splice(toastItemIndex, 1);
    }));
  }, [toastList]);

  /**
   * render all current toasts in toastList
   */
  const ToastItems = toastList.map(function renderToastItem(item) {
    return (
      <ToastItem key={item.id} removeToast={removeToast} {...item}>
        {item.content}
      </ToastItem>
    );
  });

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {createPortal(ToastItems, toastRoot)}
    </ToastContext.Provider>
  );
}

Toast.defaultProps = {
  children: null,
};

Toast.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Toast;
